import { createWebHistory, createRouter } from "vue-router";
import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    alias: "/psalms",
    name: "psalms",
    component: () => import("./components/PsalmsList.vue"),
  },
  {
    path: "/autres-compositions",
    name: "autres",
    component: () => import("./components/Autres.vue"),
  },
  {
    path: "/edito",
    name: "welcome",
    component: () => import("./components/Welcome.vue"),
  },
  {
    path: "/transcriptions-pour-orgue",
    name: "transcriptions",
    component: () => import("./components/Transcriptions.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("./components/Contact.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
