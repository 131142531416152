
import { defineComponent } from "vue";

export default defineComponent({
  name: 'MenuDetails',
  data() {
    return {
      isWelcomeSelected: false,
      isOtherScoresSelected: false,
      isSearchSelected: true,
      isTranscriptionsPourOrgueSelected: false,
      isContactSelected: false,
      isBurgerMenuOpened: false,
    }
  },
  computed: {
    getIsWelcomeSelected(): boolean {
      return this.isWelcomeSelected;
    },
    getIsOtherScoresSelected(): boolean {
      return this.isOtherScoresSelected;
    },
    getIsSearchSelected(): boolean {
      return this.isSearchSelected;
    },
    getIsTranscriptionsPourOrgueSelected(): boolean {
      return this.isTranscriptionsPourOrgueSelected;
    },
    getIsContactSelected(): boolean {
      return this.isContactSelected;
    },
    getIsBurgerMenuOpened(): boolean {
      return this.isBurgerMenuOpened;
    },
  },
  methods: {
    isSelected (menuItem: string) {
      this.isWelcomeSelected = menuItem === 'welcome';
      this.isOtherScoresSelected = menuItem === 'other-scores';
      this.isSearchSelected = menuItem === 'search';
      this.isTranscriptionsPourOrgueSelected = menuItem === 'transcriptions-pour-orgue';
      this.isContactSelected = menuItem === 'contact';

      this.isBurgerMenuOpened = false;
    },
    toggleMobileMenu() {
      this.isBurgerMenuOpened = !this.isBurgerMenuOpened;
    },
  },
});
