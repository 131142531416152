
import {defineComponent} from "vue";
import MenuDetails from "@/components/MenuDetails.vue";
import FooterDetails from "@/components/FooterDetails.vue";

export default defineComponent({
  name: "App",
  components: {
    MenuDetails,
    FooterDetails,
  },
});
